import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
//import Image from '../components/image';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h2 className="text-center">
      Welcome to RigaBee the home of collaborative media.
    </h2>
    <div className="flex content-start ">
      <div className="w-4/12 p-2" />
      <Link
        to="/register/"
        className="w-3/12 p-2 text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mx-auto">
        Get started today
      </Link>
      <div className="w-4/12 p-2" />
    </div>

    <div className="flex flex-wrap ">
    <div className="max-w-sm rounded overflow-hidden shadow-lg px-6 py-4" width="430px"> 
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 text-center">Events</div>
        <p className="text-gray-700 text-base">
          Have you ever organized a wedding, a school sports event, a charity
          event, or any kind of event that you wanted have photographed or video
          recordings of. Wouldn&apos;t it be great if anyone there with a
          smartphone could help you do this without having to try and get them
          to send these memories to you after? You can.
        </p>
      </div>
      <div className="px-6 py-4">
        <div className="flex content-start">
          <div className="w-1/6 p-2" />
          <Link
            to="/events/"
            className="w-4/6 p-2 text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mx-auto">
            View details »
          </Link>
          <div className="w-1/6 p-2" />
        </div>
      </div>
    </div>

    <div className="max-w-sm rounded overflow-hidden shadow-lg w-1/2 p-2 ">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 text-center">Entertainers</div>
        <p className="text-gray-700 text-base">
        Have you ever had that moment that you did something amazing and wished you could replay it for all of your fans.  Or thought this would have been great material to use when marketing yourself.  Or even wish you had something to memorialize what an awesome time it was for your fans.  Wouldn&apos;t it be great to have a copy of all those selfies you took with them.  You can.
        </p>
      </div>
      <div className="px-6 py-4">
        <div className="flex content-start">
          <div className="w-1/6 p-2" />
          <Link
            to="/entertainers/"
            className="w-4/6 p-2 text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mx-auto">
            View details »
          </Link>
          <div className="w-1/6 p-2" />
        </div>
      </div>
    </div>

    <div className="max-w-sm rounded overflow-hidden shadow-lg w-1/2 p-2 ">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 text-center">Producers</div>
        <p className="text-gray-700 text-base">
        Have you ever needed that camera shot, that video footage, or that audio clip for a compilation you are working on that just seems unobtainable?  What if someone already has it or can get it for you?  Our catalog and request system might get you what you need.
        </p>
      </div>
      <div className="px-6 py-4">
        <div className="flex content-start">
          <div className="w-1/6 p-2" />
          <Link
            to="/producers/"
            className="w-4/6 p-2 text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mx-auto">
            View details »
          </Link>
          <div className="w-1/6 p-2" />
        </div>
      </div>
    </div>

    <div className="max-w-sm rounded overflow-hidden shadow-lg">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 text-center">Live Streaming</div>
        <p className="text-gray-700 text-base">
        Have you ever needed that camera shot, that video footage, or that audio clip for a compilation you are working on that just seems unobtainable?  What if someone already has it or can get it for you?  Our catalog and request system might get you what you need.
        </p>
      </div>
      <div className="px-6 py-4">
        <div className="flex content-start">
          <div className="w-1/6 p-2" />
          <Link
            to="/livestreaming/"
            className="w-4/6 p-2 text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mx-auto">
            View details »
          </Link>
          <div className="w-1/6 p-2" />
        </div>
      </div>
    </div>
    </div>
  </Layout>
);

export default IndexPage;
